import { ReactElement, useState, useRef } from "react";
import * as Yup from "yup";
import {
    NxFormik,
    NxButton,
    NxFormikSelect,
    NxPopup,
    NxStack,
    NxRow,
    NxRowPosition,
    NxButtonVariant,
    NxFormikDatePicker,
    NxFormikNumberInput,
    NxFormikPercentageInput,
    NxFormikInput,
    NxLoader,
    NxFormikCashInput,
    NxCashInput
} from "@nextbank/ui-components";
import NxForm from "form/NxForm";
import NxHeader from "form/NxHeader";
import useAxios from "axios-hooks";
import { useHistory } from "react-router";
import { CommandOutputWrapper } from 'command/CommandTypes';
import { useCommand } from 'command/CommandService';
import { repricingFrequency } from "constants/loan";
import CashUtil from "tools/CashUtil";

const cashUtil = new CashUtil();
interface CreditAccommodationData {
    id?: number;
    customerId?: number;
    branchId?: number;
    providerContractNumber: string;
    otherCreditAccommodationType: string;
    contractStartDate: string | Date;
    contractEndDate: string | Date;
    recordType?: string;
    amountGranted: number;
    outstandingBalance: number;
    estimatedCreditLossesAllowance: number;
    nominalInterestRate: number;
    probabilityOfDefault: number;
    effectiveInterestRate: number;
    unearnedInterest: number;
    interestSetting: string;
    repricingFrequency?: string;
    daysPastDue: number;
    restructureCreditType?: string;
    guaranteeType?: string;
    exposureStatus: string;
    regulatoryStatus: string;
    creditPurpose?: string;
    purposeToIndustryId: number;
    creditClassification: string;
    assetAppraisedValue?: number;
    assetAppraisedDate?: string;
}

const CREDIT_CLASSIFICATION = [{
    label: 'Pass',
    value: 'UNCLASSIFIED'
}, {
    label: 'Especially Mentioned',
    value: 'ESPECIALLY_MENTIONED'
}, {
    label: 'Substandard',
    value: 'SUBSTANDARD'
}, {
    label: 'Doubtful',
    value: 'DOUBTFUL'
}, {
    label: 'Loss',
    value: 'LOSS'
}];

const CreditAccommodationSchema = Yup.object().shape({
    providerContractNumber: Yup.string().required('Provider contract number is required'),
    otherCreditAccommodationType: Yup.string().required('Credit accommodation type is required'),
    contractStartDate: Yup.date()
        .required('Contract start date is required')
        .typeError('Invalid date format')
        .max(Yup.ref('contractEndDate'), 'Must not be greater than Contract end date'),
    contractEndDate: Yup.date()
        .required('Contract end date is required')
        .typeError('Invalid date format')
        .min(Yup.ref('contractStartDate'), 'Must not be lesser than Contract start date'),
    amountGranted: Yup.number().required('Amount granted is required').min(0, "Value should be a positive number").test(cashUtil.validateMaxCash()),
    outstandingBalance: Yup.number().required('Outstanding balance is required').min(0, "Value should be a positive number").test(cashUtil.validateMaxCash()),
    estimatedCreditLossesAllowance: Yup.number().required('Estimated allowance for credit losses is required').min(0, "Value should be a positive number").test(cashUtil.validateMaxCash()),
    nominalInterestRate: Yup.number().required('Nominal interest rate is required').min(0, "Value should be a positive number").test(cashUtil.validateMaxCash()),
    probabilityOfDefault: Yup.number().required('Probability of default is required').min(0, "Value should be a positive number").test(cashUtil.validateMaxCash()),
    effectiveInterestRate: Yup.number().required('Effective interest rate is required').min(0, "Value should be a positive number").test(cashUtil.validateMaxCash()),
    unearnedInterest: Yup.number().required('Unearned interest is required').min(0, "Value should be a positive number").test(cashUtil.validateMaxCash()),
    interestSetting: Yup.string().required('Interest setting is required'),
    repricingFrequency: Yup.string().when('interestSetting', {
        is: 'REPRICEABLE',
        then: Yup.string().required('Repricing frequency is required'),
        otherwise: Yup.string().notRequired()
    }),
    daysPastDue: Yup.number().required('Days past due is required').min(0, "Value should be a positive number").test('length', 'Maximum of 4 digits', value =>
      value !== undefined && value !== null && value.toString().length <= 4
    ).integer(),
    restructureCreditType: Yup.string().notRequired(),
    guaranteeType: Yup.string().notRequired(),
    exposureStatus: Yup.string().required('Exposure status is required'),
    regulatoryStatus: Yup.string().required('Regulatory status is required'),
    creditPurpose: Yup.string().required('Purpose of credit is required'),
    purposeToIndustryId: Yup.number().required('Purpose to industry is required').typeError('Purpose to industry is required'),
    creditClassification: Yup.string().required('Credit classification is required'),
    assetAppraisedValue: Yup.number().notRequired().min(0, "Value should be a positive number").test(cashUtil.validateMaxCash(false)).nullable(),
    assetAppraisedDate: Yup.string().typeError('Must be a valid date').notRequired(),
});

interface ExposureDEIFormProps {
    hasAccess: boolean;
    exposureData: any;
    customerId: number;
    userBranchId: number;
    dictionaryEnums: any;
    loansIndustry: any;
}

const ExposureOCAForm = ({ hasAccess, exposureData, customerId, userBranchId, dictionaryEnums, loansIndustry }: ExposureDEIFormProps): ReactElement => {
    const execute = useCommand();
    const [isOCAFormLoading, setIsOCAFormLoading] = useState<boolean>(false);
    const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
    const [showSavePopup, setShowSavePopup] = useState<boolean>(false);
    const history = useHistory();
    const savedState = useRef<CreditAccommodationData>();

    const formInitValues = {
        providerContractNumber: (exposureData?.providerContractNumber || savedState?.current?.providerContractNumber) ?? '',
        otherCreditAccommodationType: (exposureData?.otherCreditAccommodationType || savedState?.current?.otherCreditAccommodationType) ?? '',
        contractStartDate: (exposureData?.contractStartDate || savedState?.current?.contractStartDate) ?? '',
        contractEndDate: (exposureData?.contractEndDate || savedState?.current?.contractEndDate) ?? '',
        amountGranted: (exposureData?.amountGranted || savedState?.current?.amountGranted) ?? null,
        outstandingBalance: (exposureData?.outstandingBalance || savedState?.current?.outstandingBalance) ?? null,
        estimatedCreditLossesAllowance: (exposureData?.estimatedCreditLossesAllowance || savedState?.current?.estimatedCreditLossesAllowance) ?? null,
        nominalInterestRate: (exposureData?.nominalInterestRate || savedState?.current?.nominalInterestRate) ?? null,
        probabilityOfDefault: (exposureData?.probabilityOfDefault || savedState?.current?.probabilityOfDefault) ?? null,
        effectiveInterestRate: (exposureData?.effectiveInterestRate || savedState?.current?.effectiveInterestRate) ?? null,
        unearnedInterest: (exposureData?.unearnedInterest || savedState?.current?.unearnedInterest) ?? null,
        interestSetting: (exposureData?.interestSetting || savedState?.current?.interestSetting) ?? 'FIXED',
        repricingFrequency: (exposureData?.repricingFrequency || savedState?.current?.repricingFrequency) ?? '',
        daysPastDue: (exposureData?.daysPastDue || savedState?.current?.daysPastDue) ?? null,
        restructureCreditType: (exposureData?.restructureCreditType || savedState?.current?.restructureCreditType) ?? '',
        guaranteeType: (exposureData?.guaranteeType || savedState?.current?.guaranteeType) ?? '',
        exposureStatus: (exposureData?.exposureStatus || savedState?.current?.exposureStatus) ?? 'ACTIVE',
        regulatoryStatus: (exposureData?.regulatoryStatus || savedState?.current?.regulatoryStatus) ?? '',
        creditPurpose: (exposureData?.creditPurpose || savedState?.current?.creditPurpose) ?? '',
        purposeToIndustryId: (exposureData?.purposeToIndustryId || savedState?.current?.purposeToIndustryId) ?? null,
        creditClassification: (exposureData?.creditClassification || savedState?.current?.creditClassification) ?? '',
        assetAppraisedValue: (exposureData?.assetAppraisedValue || savedState?.current?.assetAppraisedValue) ?? null,
        assetAppraisedDate: (exposureData?.assetAppraisedDate || savedState?.current?.assetAppraisedDate) ?? '',
    };

    return (<>
        {isOCAFormLoading ? <NxLoader/> : (
            <NxFormik<CreditAccommodationData>
                initialValues={formInitValues}
                validationSchema={CreditAccommodationSchema}
                onSubmit={async (values): Promise<void> => {
                    try {
                        setIsOCAFormLoading(true);
                        if (values.repricingFrequency === "") {
                            delete values.repricingFrequency
                        }
                        if (values.restructureCreditType === "") {
                            delete values.restructureCreditType
                        }
                        if (values.guaranteeType === "") {
                            delete values.guaranteeType
                        }
                        const response: CommandOutputWrapper<void> = await execute<CreditAccommodationData, void>({
                          name: exposureData.id ? 'UpdateExposure' : 'CreateExposure',
                          input: {
                            ...values,
                            id: exposureData.id ?? null,
                            customerId: customerId,
                            branchId: userBranchId,
                            recordType: 'OTHER_CREDIT_ACCOMMODATION',
                          }
                        });

                        if (!response.approvalRequired) {
                          history.goBack();
                        }
                    } catch (err) {
                        savedState.current = values;
                        console.error(err)
                    } finally {
                      setIsOCAFormLoading(false);
                    }
                }}
            >
                {({ values, submitForm, setFieldValue, errors }): ReactElement => {
                    return (
                      <NxForm>
                        &nbsp;
                        <NxHeader>Other Credit Accommodation</NxHeader>
                        &nbsp;
                        <NxStack>
                          <NxFormikInput
                            disabled={exposureData?.reported || !hasAccess}
                            maxLength={38}
                            name="providerContractNumber"
                            label="Provider contract number"
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="otherCreditAccommodationType"
                            label="Other credit accommodation type"
                            options={Object.entries(dictionaryEnums.OtherCreditAccommodation).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikDatePicker
                            disabled={!hasAccess}
                            name="contractStartDate"
                            label="Contract start date"
                          />
                          <NxFormikDatePicker disabled={!hasAccess} name="contractEndDate" label="Contract end date" />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="regulatoryStatus"
                            label="Regulatory status"
                            options={Object.entries(dictionaryEnums.RegulatoryStatus).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="creditPurpose"
                            label="Purpose of Credit"
                            options={Object.entries(dictionaryEnums.CreditPurpose).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="purposeToIndustryId"
                            label="Purpose to Industry"
                            options={loansIndustry}
                          />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            currency=""
                            name="amountGranted"
                            label="Amount granted"
                          />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            currency=""
                            name="outstandingBalance"
                            label="Outstanding balance"
                          />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            currency=""
                            name="estimatedCreditLossesAllowance"
                            label="Allowance for estimated credit losses "
                          />
                          <NxFormikPercentageInput
                            disabled={!hasAccess}
                            decimals={4}
                            name="nominalInterestRate"
                            label="Nominal interest rate"
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="creditClassification"
                            label="Credit classification"
                            options={CREDIT_CLASSIFICATION}
                          />
                          <NxFormikPercentageInput
                            disabled={!hasAccess}
                            decimals={4}
                            name="probabilityOfDefault"
                            label="Probability of default"
                          />
                          <NxFormikPercentageInput
                            disabled={!hasAccess}
                            decimals={4}
                            name="effectiveInterestRate"
                            label="Effective interest rate"
                          />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            decimals={2}
                            currency=""
                            name="unearnedInterest"
                            label="Unearned interest"
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="interestSetting"
                            label="Interest setting"
                            options={Object.entries(dictionaryEnums.InterestSetting).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          {values?.interestSetting === "REPRICEABLE" ? (
                            <NxFormikSelect
                              disabled={!hasAccess}
                              name="repricingFrequency"
                              label="Repricing Frequency"
                              options={JSON.parse(JSON.stringify(repricingFrequency))}
                            />
                          ) : null}
                          <NxCashInput
                            required
                            name="daysPastDue"
                            label="Days past due"
                            disabled={!hasAccess}
                            decimals={0}
                            currency=""
                            onChange={(value) => {
                              setFieldValue("daysPastDue", value)
                            }}
                            value={values.daysPastDue}
                            error={errors.daysPastDue}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="restructureCreditType"
                            label="Restructure credit type"
                            options={Object.entries(dictionaryEnums.RestructureCreditType).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="guaranteeType"
                            label="Guarantee Type"
                            options={Object.entries(dictionaryEnums.GuaranteeType).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxFormikCashInput
                            disabled={!hasAccess}
                            currency=""
                            name="assetAppraisedValue"
                            label="Asset appraised value"
                          />
                          <NxFormikDatePicker
                            disabled={!hasAccess}
                            name="assetAppraisedDate"
                            label="Asset appraised date"
                          />
                          <NxFormikSelect
                            disabled={!hasAccess}
                            name="exposureStatus"
                            label="Exposure status"
                            options={Object.entries(dictionaryEnums.ExposureStatus).map(([label, value]) => ({
                              label: label,
                              value: value,
                            }))}
                          />
                          <NxRow>
                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(true)}>
                              Cancel
                            </NxButton>
                            {hasAccess ? (
                              <NxButton variant={NxButtonVariant.SAVE} onClick={(): void => setShowSavePopup(true)}>
                                Save
                              </NxButton>
                            ) : null}
                          </NxRow>

                          <NxPopup header="Confirm" open={showSavePopup} description="Save changes?">
                            <NxRow position={NxRowPosition.END}>
                              <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowSavePopup(false)}>
                                No
                              </NxButton>
                              <NxButton
                                variant={NxButtonVariant.CONTAINED}
                                onClick={(): void => {
                                  submitForm();
                                  setShowSavePopup(false);
                                }}
                              >
                                Yes
                              </NxButton>
                            </NxRow>
                          </NxPopup>

                          <NxPopup
                            header="Confirm"
                            open={showCancelPopup}
                            description="Discard changes and go to previous screen?"
                          >
                            <NxRow position={NxRowPosition.END}>
                              <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(false)}>
                                No
                              </NxButton>
                              <NxButton
                                variant={NxButtonVariant.CONTAINED}
                                onClick={(): void => {
                                  setShowCancelPopup(false);
                                  history.goBack();
                                }}
                              >
                                Yes
                              </NxButton>
                            </NxRow>
                          </NxPopup>
                        </NxStack>
                      </NxForm>
                    );
                }}
            </NxFormik>
        )}
    </>)
}

export default ExposureOCAForm