import nxModule from 'nxModule';
import CashUtil from 'tools/CashUtil';
import templateUrl from './customer-loans-edit-interest-accrual.template.html';
import {Loan} from "../../../service/loan.types";
import {ILocationService} from "angular";
import {CustomerCache} from "components/service/customer.cache.types";
import {HttpService} from "shared/utils/httpService";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";

const cashUtil = new CashUtil();

class EditAccruedInterest {
  private customerId!: number;
  private loan!: Loan;

  private currentAccruedInterest?: number;
  private targetAccruedInterest?: number;
  private currentAccrualAllowance?: number;
  private currentNetCarryingAccrual?: number;
  private targetAccrualAllowance?: number;
  private targetNetCarryingAccrual?: number;
  private asOf?: string;
  private remarks?: string;

  constructor(
    private $location : ILocationService,
    private http: HttpService,
    private confirmation: Confirmation,
    private command: CommandService,
    private customerCache: CustomerCache
  ) {
  }

  $onInit(): void {
    this.currentAccruedInterest = this.loan.accruedInterest;
    this.currentAccrualAllowance = this.loan.accrualAllowance;
    this.currentNetCarryingAccrual = this.loan.netCarryingAccrual;
    this.targetAccruedInterest = this.loan.accruedInterest;
    this.targetAccrualAllowance = cashUtil.defaultCash(this.loan.accrualAllowance);
    this.targetNetCarryingAccrual = cashUtil.defaultCash(this.loan.netCarryingAccrual);
    this.asOf = this.loan.lastAccrualDate;
  }

  adjustment(field: string): number | undefined {
    if (field === 'accruedInterest') {
      return cashUtil.defaultCash(this.targetAccruedInterest) - cashUtil.defaultCash(this.currentAccruedInterest);
    }

    if (field === 'accrualAllowance') {
      return cashUtil.defaultCash(this.targetAccrualAllowance) - cashUtil.defaultCash(this.currentAccrualAllowance);
    }

    if (field === 'netCarryingAccrual') {
      return cashUtil.defaultCash(this.targetNetCarryingAccrual) - cashUtil.defaultCash(this.currentNetCarryingAccrual);
    }
  }

  calculateNetCarryingAccrual() : void {
    if (this.targetAccruedInterest && this.targetAccrualAllowance) {
      this.targetNetCarryingAccrual = this.targetAccruedInterest - this.targetAccrualAllowance;
    }
  }

  goBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  async save(): Promise<void> {
    const confirmed = await this.confirmation('Apply interest accrual adjustment?');
    if (confirmed) {
      await this.command.execute("EditLoanInterestAccrual", {
        targetAccruedInterest: this.targetAccruedInterest,
        targetAccrualAllowance: this.targetAccrualAllowance,
        targetNetCarryingAccrual: this.targetNetCarryingAccrual,
        remarks: this.remarks,
        productId: this.loan.id
      }).toPromise();

      this.customerCache.loans(this.customerId).refetch();
      this.goBack();
    }
  }
}

nxModule.component('customerLoansEditInterestAccrual', {
  templateUrl,
  controller: EditAccruedInterest,
  bindings: {
    customerId: '<',
    loan: '<'
  }
});
