import nxModule from 'nxModule';
import $ from 'jquery';
import BigNumber from 'bignumber.js';
import moment from "moment";

import './amortization-preview.component';
import {calculateExcessiveAmount} from '../common/loan-calculations';

const templateUrl = require('./loan-preview.template.html');
nxModule.component('loanPreview', {
  templateUrl: templateUrl,
  bindings: {
    loan: '<',
    loanType: '<',
    waiveRequest: '<'
  },
  controller: function ($scope, $timeout) {

    $scope.$watch('$ctrl.loan', () => {
      if (!this.loan) return;

      $timeout(() => {
        let loanPreview = $('loan-preview');
        $('html,body').animate({scrollTop: loanPreview.offset().top});
      });
    });

    this.calculateChargesAndFees = () => {
      let chargesAndFees = new BigNumber(this.loan.principalAmount)
          .minus(new BigNumber(this.loan.releaseAmount));

      if (this.isRenewedLoan()) {
        chargesAndFees = chargesAndFees.minus(new BigNumber(this.loan.renewedFromRemainingBalance));
      }

      return chargesAndFees.toNumber();
    };

    this.isRestructuredLoan = () => {
      return this.loan.creationType === 'RESTRUCTURED'
    };

    this.isConsolidatedLoan = () => {
      return this.loan.creationType === 'CONSOLIDATION'
    };

    this.isRenewedLoan = () => {
      return this.loan.renewedLoanId && this.loan.creationType === 'RENEWAL';
    };

    this.calculateExcessiveAmount = () => {
      return calculateExcessiveAmount(this.loan.releaseAmount, this.waiveRequest.total.totalFinalBalanceToPay);
    };

    this.calculateRemadeReleaseAmount = () => {
      return new BigNumber(this.loan.releaseAmount)
        .minus(new BigNumber(this.loan.remadeLoanBalance))
        .toNumber();
    };

    this.getCalendarDays = () => {
      if(!this.loan){
        return;
      }

      const format = 'YYYY-MM-DD';
      const maturity = moment(this.loan.maturityDate, format);
      const grant = moment(this.loan.grantDate, format);
      return maturity.diff(grant, 'days');
    };
  }
});
