import nxModule from 'nxModule';
import _ from 'lodash';

function extractEntries(entries) {
  let result = [];
  for (let e of entries) {
    let id = e.id;
    let code = e.code;
    const d = _.find(e.values, {languageCode: 'en'});
    if (!d) {
      console.error(`ERROR: no dict i18n entry found for code:  ${code}`);
    } else {
      if (code === 'ID23') {
        // split National ID into two entries w/ the same id and code ID23
        result.push({
          id: id,
          code: code,
          description: 'Printed e-Philippine Identification (ePhilID)',
          attributes: e.attributes
        });
        result.push({
          id: id,
          code: code,
          description: 'Philippine Identification System (PhilSys) Digital ID',
          attributes: e.attributes
        });
      } else {
        result.push({id: id, code: code, orderNo: e.orderNo, description: d.value, attributes: e.attributes});
      }
    }
  }
  return result;
}

nxModule.factory('dict', function (dictionaryCache) {
  let dict = {};
  let loaded = false;
  let callbacks = [];

  dictionaryCache.toObservable().subscribe(response => {
    for (let i = 0; i < response.length; i++) {
      let d = response[i];
      let code = d.code;
      let entries = d.entries;
      dict[code] = extractEntries(entries);
    }

    loaded = true;
    // TODO: remove callbacks and chagne to observable
    for (let c of callbacks) {
      c();
    }
  });

  dict.getId = (dictionary, entryCode) => {
    if (!dict[dictionary]) return null;
    const entry = _.find(dict[dictionary], {code: entryCode});
    return entry ? entry.id : null;
  };

  dict.getEntry = (dictionary, id) => {
    if (!dict[dictionary]) return null;
    return _.find(dict[dictionary], {id: id});
  };

  dict.getDescription = (dictionary, id) => {
    if (!dict[dictionary]) return null;
    const entry = _.find(dict[dictionary], {id: id});
    return entry ? entry.description : null;
  };

  dict.onLoadingCompleteAsync = () => new Promise(resolve => dict.onLoadingComplete(resolve));

  dict.onLoadingComplete = (callback) => {
    if (loaded) {
      callback();
    } else {
      callbacks.push(callback);
    }
  };

  dict.filterByAttribute = (dictionary, attrName, ...attrValues) => {
    if (!dict[dictionary]) return null;
    return dict[dictionary].filter(entry => {
      return entry
        && entry.attributes
        && entry.attributes.hasOwnProperty(attrName)
        && Array.isArray(entry.attributes[attrName])
        && attrValues.some(attrValue => entry.attributes[attrName].includes(attrValue));
    })
  };

  return dict;
});
