import nxModule from 'nxModule';
import templateUrl from './customer-loans-edit-credit-losses-allowance.template.html'
import {Loan} from "../../../service/loan.types";
import {ProductStatus} from 'components/service/product.types';
import {ILocationService} from "angular";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";

class EditCreditLossesAllowance {
  private customerId!: number;
  private loan!: Loan;

  private status?: ProductStatus;
  private principalBalance?: number;
  private provisionRate?: number;
  private currentCreditLossesAllowance?: number;
  private targetCreditLossesAllowance?: number;
  private remarks?: string;

  constructor(
    private $location : ILocationService,
    private confirmation: Confirmation,
    private command: CommandService,
    private customerCache: CustomerCache
  ) {
  }

  $onInit(): void {
    this.status = this.loan.status;
    this.principalBalance = this.loan.principalBalance;
    this.provisionRate = this.loan.metadata.provisionRate;
    if (this.loan.estimatedCreditLossesAllowance === 0) {
      this.currentCreditLossesAllowance = this.principalBalance * this.provisionRate / 100;
    } else {
      this.currentCreditLossesAllowance = this.loan.estimatedCreditLossesAllowance;
    }
  }

  adjustment(field: string): number | undefined {
    if (field === 'creditLossesAllowance' && this.targetCreditLossesAllowance !== undefined && this.currentCreditLossesAllowance !== undefined) {
      return this.targetCreditLossesAllowance - this.currentCreditLossesAllowance;
    }
  }

  goBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  async save(): Promise<void> {
    const message = this.targetCreditLossesAllowance && this.targetCreditLossesAllowance > 0
      ? 'Override the current state of the allowance for credit losses?' : 'Use metadata default amount as the loan\'s allowance for credit losses?';
    const confirmed = await this.confirmation(message);
    if (confirmed) {
      await this.command.execute("EditLoanAllowanceForCreditLosses", {
        targetCreditLossesAllowance: this.targetCreditLossesAllowance,
        remarks: this.remarks,
        productId: this.loan.id
      }).toPromise();

      this.customerCache.loans(this.customerId).refetch();
      this.goBack();
    }
  }
}

nxModule.component('customerLoansEditCreditLossesAllowance', {
  templateUrl,
  controller: EditCreditLossesAllowance,
  bindings: {
    customerId: '<',
    loan: '<'
  }
});
