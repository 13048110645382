import angular from "angular-route";

export const complianceRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/compliance/red-flag-disposition', {
    template: '<red-flag-disposition/>',
    label: 'Red Flag Disposition'
  }],
  ['/admin/compliance/risk/assessment', {
    template: '<risk-assessment-view/>',
    label: 'Risk assessment'
  }],
  ['/admin/compliance/risk/categories', {
    template: '<risk-categories-view/>',
    label: 'Risk categories'
  }],
  ['/admin/compliance/risk/categories/create', {
    template: '<risk-category-details-view/>',
    label: 'Create new risk category'
  }],
  ['/admin/compliance/risk/categories/:riskCategoryId', {
    template: '<risk-category-details-view/>',
    label: 'risk-category-label'
  }],
  ['/admin/compliance/watchlist', {
    template: '<watchlist-view/>',
    label: 'Watchlist'
  }],
  ['/admin/compliance/watchlist/create', {
    template: '<watchlist-details-view/>',
    label: 'Create new watchlist'
  }],
  ['/admin/compliance/watchlist/:watchlistId', {
    template: '<watchlist-details-view/>',
    label: 'watchlist-label'
  }]
];
