import {isNil} from 'lodash/fp';

export default class CashUtil {
  defaultCash(number?: number): number {
    if (isNil(number)) {
      return 0;
    }

    return number;
  }
  // For Yup cash fields validation only
  validateMaxCash = (isValueRequired = true) => ({
    name: "maxCash",
    message: `Maximum number limit reached`,
    test: (value: number) => {
      if (!isValueRequired && !value) {
        return true;
      } else {
        return value !== undefined && value !== null && value < 9223372036854.77;
      }
    }
  });
}