import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './boolean-condition.template.html';
import './boolean-condition.style.less';

class DictionaryConditionComponent {
    constructor($scope) {
        this.$scope = $scope;
    }

    $onInit() {
        this.type = 'BOOLEAN';

        this.$scope.$watchCollection('$ctrl.conditions', () => {
            if (!this.propertyDefinition) {
                return;
            }

            if (!this.isConditionAlreadyDefined()) {
                this.setDefaultValues();
            } else {
                this.fetchExistingValues();
            }
        });

        this.valueChanged = () => {
            this.saveValues();
        };
    }

    setDefaultValues() {
        this.model = {param: null};
    }

    fetchExistingValues() {
        this.model = this.conditions[this.propertyDefinition.property].model;
    }

    /**
     * Gets the only one allowed predicate for this type
     *
     * @returns {*}
     */
    getSupportedPredicate() {
        return this.propertyDefinition.supportedPredicates[0];
    }

    saveValues() {
        this.conditions[this.propertyDefinition.property] = {
            properties: [this.propertyDefinition.property],
            type: this.propertyDefinition.type,
            model: this.model,
            predicate: this.getSupportedPredicate(),
            options: []
        };
    }

    isConditionAlreadyDefined() {
        return _.has(this.conditions, this.propertyDefinition.property);
    }
}

nxModule.component('booleanCondition', {
    templateUrl,
    bindings: {
        conditions: '=',
        propertyDefinition: '<'
    },
    controller: DictionaryConditionComponent
});
