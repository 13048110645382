import nxModule from 'nxModule';

import templateUrl from './watchlist.template.html';
import './watchlist.style.less';

class WatchlistView {
  constructor(http, confirmation, command, $location, $route) {
    this.http = http;
    this.confirmation = confirmation;
    this.command = command;
    this.$location = $location;
    this.$route = $route;
  }

  async $onInit() {
    this.watchlist = await this.http.get('/management/watchlist').toPromise();
  }

  goBack() {
    this.$location.path('/admin/compliance')
  }

  async removeWatchlist(id) {
    const confirmation = await this.confirmation('Do you want to remove this watchlist?');
    if (!confirmation) {
      return;
    }

    const response = await this.command.execute('DeleteWatchlist', {id: id}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  }
}

nxModule.component('watchlistView', {
  templateUrl,
  controller: WatchlistView
});