import nxModule from 'nxModule';
import _ from 'lodash';
import systemPropertyService from 'system/systemPropertyService';

import templateUrl from './risk-categories.template.html';
import './risk-categories.style.less';

class RiskCategoriesView {
  constructor(http, $route, $location, confirmation, command) {
    this.http = http;
    this.$route = $route;
    this.$location = $location;
    this.confirmation = confirmation;
    this.command = command;
    this.systemPropertyService = systemPropertyService;
  }

  async $onInit() {
    [this.properties, this.riskCategories] = await Promise.all([
      this.systemPropertyService.getPropertyDescriptors(),
      this.http.get('/management/risk/categories').toPromise()
    ]);
    const corpoProp = _.find(this.properties, {code: 'CIF_CORPORATE_SUPPORT'});
    this.corporateCustomerEnabled = corpoProp && corpoProp.value === 'TRUE';

    this.riskCategories = this.riskCategories.map(
      r => ({
        ...r,
        individualPercentage: r.customerTypes.includes('INDIVIDUAL') ? r.weightPercentage : null,
        corporatePercentage: r.customerTypes.includes('CORPORATE') ? r.weightPercentage : null
      })
    );

    this.totalPercentages();
  }

  async removeCategory(id) {
    const confirmation = await this.confirmation('Do you want to remove this risk category?');
    if (!confirmation) {
      return;
    }

    const response = await this.command.execute('DeleteRiskCategory', {id: id}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  }

  goBack() {
    this.$location.path('/admin/compliance')
  }

  refreshDisplayedPercentages(id, weightPercentage) {
    this.riskCategories = this.riskCategories
      .map(r => {
          let percentage = id === r.id
            ? weightPercentage
            : r.weightPercentage;
          return ({
            ...r,
            individualPercentage: r.customerTypes.includes('INDIVIDUAL') ? percentage : null,
            corporatePercentage: r.customerTypes.includes('CORPORATE') ? percentage : null
          });
        }
      );
    this.totalPercentages();
  }

  totalPercentages() {
    this.totalIndividualPercentage = _.sumBy(this.riskCategories, it => it.individualPercentage);
    this.totalCorporatePercentage = _.sumBy(this.riskCategories, it => it.corporatePercentage);
  }

  async savePercentages() {
    const riskCategoryPercentages = this.riskCategories
      .map(r => ({
        riskCategoryId: r.id,
        weightPercentage: r.weightPercentage,
        customerTypes: r.customerTypes
      }));

    const response = await this.command.execute('UpdateRiskCategoryPercentages',
      {riskCategoryPercentages: riskCategoryPercentages}).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.$route.reload();
  }
}

nxModule.component('riskCategoriesView', {
  templateUrl,
  controller: RiskCategoriesView
});
