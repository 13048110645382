import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './condition.template.html';
import './condition.style.less';

class ConditionComponent {
  constructor($scope) {
    this.$scope = $scope;
  }

  $onInit() {
    this.$scope.$watchCollection('$ctrl.conditions', () => {
      this.exists = _.has(this.conditions, this.property);
    });
  }

  close() {
    delete this.conditions[this.property]
  }
}

nxModule.component('condition', {
  templateUrl,
  transclude: true,
  bindings: {
    conditions: '=',
    property: '<'
  },
  controller: ConditionComponent
});
