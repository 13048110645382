import {
  accountIdResolver,
  accountTypeIdResolver,
  checkbookIdResolver,
  customerIdResolver,
  customerResolver,
  checkPermissions,
  groupIdResolver,
  productIdFromAccountIdResolver,
  reportCodeResolver
} from './resolvers';
import angular from "angular-route";
import {NxRouteService} from "routes/NxRouteService";

export const customerPath = '/customer/:customerId';
const customerProfilePath = `${customerPath}/profile`;
const customerRiskProfilePath = `${customerPath}/risk-profile`;
const customerAccountsPath = `${customerPath}/accounts`;
const customerAccountPath = `${customerAccountsPath}/:accountId`;
export const groupCustomerPath = '/customer/:groupCustomerId'; // Customer (CustomerType: GROUP)

const routePermissionContext = ($route: NxRouteService): {customerId: number} => {
  return {
    customerId: Number($route.current.params['customerId'])
  };
};

export const customerRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  [customerPath, {
    // redirect to lookup customer as customer landing template is currently empty
    redirectTo: customerProfilePath,
    template: '<customer-landing-page>',
    label: 'customer' // replaced by customer name in controllers
  }], [customerProfilePath, {
    template: '<customer-profile-page customer-id="$resolve.customerId"/>',
    label: 'Profile',
    resolve: {
      ...customerIdResolver
    },
    resolveRedirectTo: checkPermissions(['CST_ALL_BRANCHES_ACCESS'], routePermissionContext)
  }], [customerRiskProfilePath, {
    template: '<customer-risk-profile-page customer-id="$resolve.customerId"/>',
    label: 'Risk Profile',
    resolve: {
      ...customerIdResolver
    },
    resolveRedirectTo: checkPermissions(['CST_ALL_BRANCHES_ACCESS'], routePermissionContext)
  }], [`${customerProfilePath}/force`, {
    template: '<customer-profile-page customer-id="$resolve.customerId" force-edit-mode="true"/>',
    label: 'Profile',
    resolve: {
      ...customerIdResolver
    },
    resolveRedirectTo: checkPermissions(['CST_ALL_BRANCHES_ACCESS'], routePermissionContext)
  }], [`${customerProfilePath}/merge`, {
    template: '<customer-merge-customers customer-id="$resolve.customerId"/>',
    label: 'Customer - merge with',
    resolve: {
      ...customerIdResolver
    },
    resolveRedirectTo: checkPermissions(['CST_ALL_BRANCHES_ACCESS'], routePermissionContext)
  }], [`${customerPath}/statements`, {
    template: '<report-list report-type="\'customer-statements\'">',
    label: 'Statements'
  }], [`${customerPath}/statements/:reportCode`, {
    template: '<report-details report-code="$resolve.reportCode" customer-id="$resolve.customerId"/>',
    label: 'report-details',
    resolve: {
      ...customerIdResolver,
      ...reportCodeResolver
    }
  }],

  // accounts
  [customerAccountsPath, {
    template: '<customer-accounts/>',
    label: 'Deposit Accounts'
  }], [`${customerAccountsPath}/create`, {
    template: '<customer-account-create-list/>',
    label: 'Open New Deposit Account'
  }], [`${customerAccountsPath}/create/:accountTypeId`, {
    template: '<customer-account-create-details/>',
    label: '<null>'
  }], [customerAccountPath, {
    template: '<customer-accounts/>',
    label: '<null>'
  }], [`${customerAccountPath}/details`, {
    template: '<customer-account-details-print customer-id="$resolve.customerId" account-id="$resolve.accountId"/>',
    label: 'Details',
    resolve: {
      ...accountIdResolver,
      ...customerIdResolver
    }
  }], [`${customerAccountPath}/cash-deposit`, {
    template: '<customer-accounts-cash-deposit/>',
    label: 'Cash Deposit'
  }], [`${customerAccountPath}/cash-withdraw`, {
    template: '<customer-accounts-cash-withdraw/>',
    label: 'Cash Withdrawal'
  }], [`${customerAccountPath}/check-deposit`, {
    template: '<customer-accounts-check-deposit/>',
    label: 'Check Deposit'
  }], [`${customerAccountPath}/multiple-checks-deposit`, {
    template: '<customer-accounts-multiple-checks-deposit/>',
    label: 'Check Deposit'
  }], [`${customerAccountPath}/pesonet-transfer`, {
    template: '<pesonet-transfer customer-id="$resolve.customerId" product-id="$resolve.productId"/>',
    label: 'PESONet External Transfer',
    resolve: {
      ...customerIdResolver,
      ...productIdFromAccountIdResolver
    }
  }], [`${customerAccountPath}/check-withdrawal`, {
    template: '<customer-accounts-check-withdrawal/>',
    label: 'Check Withdrawal'
  }], [`${customerAccountPath}/encash-check`, {
    template: '<encash-on-us-check/>',
    label: 'Encash on-us check',
  }], [`${customerAccountPath}/credit-memo`, {
    template: '<customer-accounts-memo/>',
    label: 'Credit Memo'
  }], [`${customerAccountPath}/debit-memo`, {
    template: '<customer-accounts-memo/>',
    label: 'Debit Memo'
  }], [`${customerAccountPath}/transfer-to-product`, {
    template: '<transfer-to-product customer-id="$resolve.customerId" product-id="$resolve.productId" product-group="\'ACCOUNT\'"/>',
    label: 'Transfer funds to product',
    resolve: {
      ...customerIdResolver,
      ...productIdFromAccountIdResolver
    }
  }], [`${customerAccountPath}/create-hold`, {
    template: '<create-account-hold/>',
    label: 'Create hold'
  }], [`${customerAccountPath}/create-lock`, {
    template: '<create-lock/>',
    label: 'Create lock'
  }], [`${customerAccountPath}/activate-account`, {
    template: '<customer-accounts-activate-account/>',
    label: 'Activate account'
  }], [`${customerAccountPath}/close/zero-balance`, {
    template: '<close-account mode="CloseZeroBalance" customer-id="$resolve.customerId" product-id="$resolve.productId" page-header="CLOSE ACCOUNT"/>',
    label: 'Close account',
    resolve: {
      ...customerIdResolver,
      ...productIdFromAccountIdResolver
    }
  }], [`${customerAccountPath}/close/cash`, {
    template: '<close-account mode="CloseWithCash" customer-id="$resolve.customerId" product-id="$resolve.productId" page-header="CLOSE ACCOUNT WITH CASH"/>',
    label: 'Close account with cash',
    resolve: {
      ...customerIdResolver,
      ...productIdFromAccountIdResolver
    }
  }], [`${customerAccountPath}/close/check`, {
    template: '<close-account mode="CloseWithCheck" customer-id="$resolve.customerId" product-id="$resolve.productId" page-header="CLOSE ACCOUNT WITH CHECK"/>',
    label: 'Close account with check',
    resolve: {
      ...customerIdResolver,
      ...productIdFromAccountIdResolver
    }
  }], [`${customerAccountPath}/close/check-encashment`, {
    template: '<close-account mode="CloseWithCheckEncash" customer-id="$resolve.customerId" product-id="$resolve.productId" page-header="CLOSE ACCOUNT WITH CHECK ENCASHMENT"/>',
    label: 'Close account with check encashment',
    resolve: {
      ...customerIdResolver,
      ...productIdFromAccountIdResolver
    }
  }], [`${customerAccountPath}/stop-order`, {
    template: '<customer-accounts-stop-order/>',
    label: 'Create stop order'
  }], [`${customerAccountPath}/credit-on-us-check`, {
    template: '<customer-accounts-credit-on-us-check customer-id="$resolve.customerId" account-id="$resolve.accountId"/>',
    label: 'Credit on-us check',
    resolve: {
      ...customerIdResolver,
      ...accountIdResolver
    }
  }], [`${customerAccountPath}/edit`, {
    template: '<customer-account-edit/>',
    label: 'Edit account parameters'
  }], [`${customerAccountPath}/edit-signature-card`, {
    template: '<customer-account-edit-signature-card customer-id="$resolve.customerId" account-id="$resolve.accountId"/>',
    label: 'Edit account signature card',
    resolve: {
      ...customerIdResolver,
      ...accountIdResolver
    }
  }], [`${customerAccountPath}/edit-collector`, {
    template: '<customer-account-edit-collector customer-id="$resolve.customerId" account-id="$resolve.accountId"/>',
    label: 'Edit account collector',
    resolve: {
      ...customerIdResolver,
      ...accountIdResolver
    }
  }], [`${customerAccountPath}/edit-ata`, {
    template: '<customer-account-edit-ata/>',
    label: 'Edit account ATA'
  }], [`${customerAccountPath}/issue-checkbook`, {
    template: '<issue-checkbook/>',
    label: 'Issue checkbook'
  }], [`${customerAccountPath}/checkbook/:checkbookId`, {
    template: '<checkbook-update checkbook-id="$resolve.checkbookId" customer-id="$resolve.customerId" account-id="$resolve.accountId"/>',
    label: 'Update checkbook',
    resolve: {
      ...checkbookIdResolver,
      ...customerIdResolver,
      ...accountIdResolver
    }
  }], [`${customerAccountPath}/issue-passbook`, {
    template: '<issue-passbook product-group="\'DEPOSIT_ACCOUNT\'"/>',
    label: 'Issue passbook'
  }], [`${customerAccountPath}/issue-passbook/edit`, {
    template: '<issue-passbook product-group="\'DEPOSIT_ACCOUNT\'" edit-mode="true"/>',
    label: 'Update passbook'
  }], [`${customerAccountPath}/group`, {
    template: '<account-group-list customer-id="$resolve.customerId" account-id="$resolve.accountId"/>',
    label: 'Available account groups',
    resolve: {
      ...customerIdResolver,
      ...accountIdResolver
    }
  }], [`${customerAccountPath}/group/:typeId/create`, {
    template: '<account-group-details customer-id="$resolve.customerId" account-id="$resolve.accountId" type-id="$resolve.typeId"/>',
    label: 'Create account group',
    resolve: {
      ...accountTypeIdResolver,
      ...customerIdResolver,
      ...accountIdResolver
    }
  }], [`${customerAccountPath}/group/:typeId/update/:groupId`, {
    template: '<account-group-details customer-id="$resolve.customerId" account-id="$resolve.accountId" type-id="$resolve.typeId" group-id="$resolve.groupId"/>',
    label: 'Update account group',
    resolve: {
      ...accountIdResolver,
      ...accountTypeIdResolver,
      ...customerIdResolver,
      ...groupIdResolver
    }
  }]
];
