import nxModule from 'nxModule';
import _ from 'lodash';
import BigNumber from 'bignumber.js';

import PayHelper from './pay-helper'
import {sum} from 'shared/common/MathUtils';
import systemPropertyService from '../../../../../react/system/systemPropertyService';
import {advancePaymentStrategies} from 'constants/loan';

const templateUrl = require('./amortization-payment.template.html');
nxModule.component('amortizationPayment', {
  templateUrl: templateUrl,
  bindings: {
    /**
     * Loan to be paid. The loan must include a loanType parameter initialized with its loan type.
     * **/
    'loan': '<',

    /**
     * Object that is returned by this component. Consists of:
     * amount - The filled in amount to be paid
     * custom payment amounts (principalAmount, interestAmount, ...) - returned if custom payment
     * temporaryReceipt: Temporary Receipt (if applicable)
     * officialReceipt: Official Receipt (if applicable)
     */
    'paymentRequest': '=',

    /**
     * Describes if the receipt fields should be collected
     */
    collectTemporaryReceipt: '<',
    collectOfficialReceipt: '<',
    allowOverpayment: '<',
    customPaymentAllowed: '<'
  },
  controller: function ($scope, $location, http, customerCache, productDefinitionService, $filter, systemDateService, authentication, propertyConfigService) {
    let that = this;

    that.cfg = propertyConfigService;
    that.previewAmortizations = [];
    that.error = null;
    that.canPerformCustomPayment = false;
    that.shouldUserSelect = false;
    that.shouldTransferToCBU = false;
    that.valueDateEnabled = systemPropertyService.getProperty('LOAN_PAYMENT_VALUE_DATE_ENABLED') === 'TRUE';
    that.branchSystemDate = null;
    that.advanceTransfer = false;
    that.availableAdvancePaymentStrategies = [];
    that.loanPDCList = [];
    that.autoReceiptEnabled = systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE';
    that.displayEmptyReceiptMessage = false;
    that.officialReceipt = null;
    that.excessNonHierarchyPayment = new BigNumber(0);

    if (!that.paymentRequest) {
      that.paymentRequest = {};
    }

    /**
     * Used on the custom payment handling for 'remembering' the input amount
     */
    let paymentRequestAmount;

    const formatAccountName = async (accId, productDefinitions) => {
      const account = await http.get(`/products/accounts/${accId}`).toPromise();
      const productDefinition = productDefinitions.find(product => product.id === account.definitionId);
      return productDefinition.productName + ' ' + account.productNumber;
    };

    that.$onInit = async () => {
      if(that.autoReceiptEnabled) {
        that.officialReceipt = await http.get(`/official-receipt/next-available?userId=${authentication.context.id}`)
        .toPromise()
        .catch(response => {
          if(response?.errorCode === 'NO_AVAILABLE_OFFICIAL_RECEIPT') {
            that.displayEmptyReceiptMessage = true;
          } else {
            throw response;
          }
        });

        if(that.officialReceipt) {
          that.paymentRequest.officialReceipt = that.officialReceipt.receiptNumber;
        }
      }
    };

    that.$onChanges = async () => {
      if (!that.loan) {
        return;
      }
      that.branchSystemDate = await systemDateService.getSystemDateByBranchId(that.loan.branchId);
      const due = _.filter(that.loan.amortizationSchedule.list, a => a.status === 'DUE' || a.status === 'OVERDUE');
      that.paymentRequest.amount = _.reduce(due, (sum, a) => sum.plus(a.amortizationBalance), new BigNumber(0)).toNumber();

      if (!that.paymentRequest.amount) {
        // no due / overdue amortizations. Calculate total amount of first unpaid amortization
        const loanType = that.loan.loanType;
        const advancePaymentHierarchy = convertHierarchyToFields(loanType.advancePaymentHierarchy);
        let unpaid = _.filter(that.loan.amortizationSchedule.list, a => a.status === 'UNPAID');

        if (!unpaid) return;  // no unpaid amortizations for some reason... returning..

        const a = unpaid[0];
        let sum = new BigNumber(0);
        for (let h of advancePaymentHierarchy) {
          sum = sum.plus(a[h]);
        }
        that.paymentRequest.amount = sum.toNumber();
      }

      if (!that.customPayment) {
        that.calculatePreview();
      }

      that.loanPDCList = await customerCache.loanPDCs(that.loan.customerId,that.loan.id).toPromise();

      const productDefinitions = await productDefinitionService.toPromise();

      if (that.loan.cbuFundAccountId) {
        that.cbuAccountDetails = await formatAccountName(that.loan.cbuFundAccountId, productDefinitions);
      }

      if (that.loan.linkedDepositAccountId) {
        that.linkedDepositAccountDetails = await formatAccountName(that.loan.linkedDepositAccountId, productDefinitions);
      }

      that.availableAdvancePaymentStrategies = advancePaymentStrategies.filter(strat => {
        if(strat.value === 'USER_SELECT') {
          return false;
        }

        if(strat.value === 'TRANSFER_TO_CBU' && !that.loan.cbuFundAccountId) {
          return false;
        }

        if(strat.value === 'TRANSFER_TO_LINKED_ACCOUNT' && !that.loan.linkedDepositAccountId) {
          return false;
        }

        return true;
      });

      if (that.loan.loanType.advancePaymentStrategy !== 'USER_SELECT') {
        that.paymentRequest.selectedAdvancePaymentStrategy = that.loan.loanType.advancePaymentStrategy;
      } else if (that.loan.loanType.userDefaultAdvancePaymentStrategy) {
        that.paymentRequest.selectedAdvancePaymentStrategy = that.loan.loanType.userDefaultAdvancePaymentStrategy;
      } else if(that.availableAdvancePaymentStrategies.length === 0){
        that.paymentRequest.selectedAdvancePaymentStrategy = that.availableAdvancePaymentStrategies[0].value;
      }
    };

    that.customPaymentPartArray = [
      {label: 'Principal', property: 'principalAmount'},
      {label: 'Interest', property: 'interestAmount'},
      {label: 'Cbu', property: 'cbuChargeAmount'},
      {label: 'Pf', property: 'pfChargeAmount'},
      {label: 'Tp', property: 'tpChargeAmount'},
      {label: 'Past Due Interest', property: 'pastDueInterestAmount'},
      {label: 'Penalty', property: 'penaltyAmount'},
      {label: 'Custom Fees', property: 'customFeesAmount'}
    ];

    that.onCustomPaymentChange = () => {
      if (that.customPayment) {
        paymentRequestAmount = that.paymentRequest.amount;
        that.clearCustomPayment();
      } else {
        that.paymentRequest.amount = paymentRequestAmount;
        that.calculatePreview();
      }
    };

    that.clearCustomPayment = () => {
      that.paymentRequest.principalAmount = undefined;
      that.paymentRequest.interestAmount = undefined;
      that.paymentRequest.cbuChargeAmount = undefined;
      that.paymentRequest.pfChargeAmount = undefined;
      that.paymentRequest.tpChargeAmount = undefined;
      that.paymentRequest.pastDueInterestAmount = undefined;
      that.paymentRequest.penaltyAmount = undefined;
      that.paymentRequest.customFeesAmount = undefined;

      that.paymentRequest.amount = 0;
      that.paymentRequest.valueDate = undefined;
      that.paymentRequest.remarks = undefined;
      that.modifiedAmortizations = undefined;
    };

    /**
     * Payment methods manually transpiled from LoanPaymentService.java
     */

    that.calculatePreview = () => {
      if (that.paymentRequest.amount == null || !that.loan) return;

      const loan = _.cloneDeep(that.loan);
      setInitialValues(loan);
      that.error = null;
      that.warning = null;
      that.excessNonHierarchyPayment = new BigNumber(0);

      convertToBigNumber(loan);

      const selectedAdvancePaymentStrategy = this.getSelectedAdvancePaymentStrategy();

      let payHelper;
      if (that.loan.loanType.paymentDirection === 'VERTICAL') {
        payHelper = calculateVerticalPayment(loan, selectedAdvancePaymentStrategy);
      } else if (that.loan.loanType.paymentDirection === 'HORIZONTAL') {
        payHelper = calculateHorizontalPayment(loan, selectedAdvancePaymentStrategy);
      } else {
        console.log("Unknown payment direction: " + that.loan.loanType.paymentDirection);
      }

      if (!payHelper.remainingAmount.isZero()) {
        // all amortizations paid and still some money left -> paid amount is bigger than expected
        if (that.allowOverpayment && that.loan.loanType.allowOverpayment) {
          // overpayment if there's more money left than the loan can take
          that.overpayment = true;
          that.warning = `Excessive amount will be treated as overpayment: ${$filter('nxCurrency')(payHelper.remainingAmount)}`;
        } else {
          that.error = `Payment amount greater than expected. Excessive amount: ${$filter('nxCurrency')(payHelper.remainingAmount)}`;
        }
      } else {
        // remaining amount is zero
        that.overpayment = false;
        that.paymentRequest.overpaymentGLAccount = null;
      }

      postProcess(loan);
      convertFromBigNumber(loan);
      that.modifiedAmortizations = payHelper.modifiedAmortizations;
      that.advancePayment = false;
      if (that.modifiedAmortizations.filter(a => a.status_initial == 'UNPAID').length > 0 || !that.excessNonHierarchyPayment.isZero()) {
        that.advancePayment = true;

        if(['TRANSFER_TO_CBU','TRANSFER_TO_LINKED_ACCOUNT'].includes(selectedAdvancePaymentStrategy)){
          // display all due and overdue amortization with payment
          that.modifiedAmortizations = that.modifiedAmortizations.filter(a => ['DUE','OVERDUE'].includes(a.status_initial));;
        }
      }

      that.totalPaidAmount = _.reduce(that.modifiedAmortizations, (sum, a) =>
        sum.plus(a.paid), new BigNumber(0)
      ).plus(that.excessNonHierarchyPayment).toNumber();
      that.excessNonHierarchyPayment = that.excessNonHierarchyPayment.toFixed(2);
    };

    that.getSelectedAdvancePaymentStrategy = () => {
      if(that.loan.loanType.advancePaymentStrategy == 'USER_SELECT') {
        if(that.paymentRequest.selectedAdvancePaymentStrategy) {
          return that.paymentRequest.selectedAdvancePaymentStrategy;
        }
        return that.loan.loanType.userDefaultAdvancePaymentStrategy;
      }
      return that.loan.loanType.advancePaymentStrategy;
    }

    that.isPDCConflict = () => {
      return (that.modifiedAmortizations || [])
        .map(a => a.id)
        .some(amortizationId => {
          const matchingPDC = (that.loanPDCList || []).find(pdc => Number(pdc.amortizationId) === Number(amortizationId));
          return matchingPDC && matchingPDC.processStatus === 'REGISTERED';
        });
    };

    function calculateHorizontalPayment(loan, selectedAdvancePaymentStrategy) {
      const loanType = loan.loanType;
      const bnAmount = bn(that.paymentRequest.amount);

      const payHelper = new PayHelper(bnAmount);
      const payments = {};
      const duePaymentHierarchy = convertHierarchyToFields(loanType.duePaymentHierarchy);
      const advancePaymentHierarchy = convertHierarchyToFields(loanType.advancePaymentHierarchy);

      // 1. Pay due and overdue amortizations
      if (!payHelper.remainingAmount.isZero()) {
        let dueAmortizations = _.filter(loan.amortizationSchedule.list, a => a.status === 'DUE' || a.status === 'OVERDUE');
        markAmortizationsAsPaidHorizontally(payHelper.remainingAmount, payments, dueAmortizations, duePaymentHierarchy, payHelper);
      }
      // 2. Handle advance payment
      if (!payHelper.remainingAmount.isZero()) {
        let unpaidAmortizations = _.filter(loan.amortizationSchedule.list, a => a.status === 'UNPAID');

        if(selectedAdvancePaymentStrategy == 'PAYMENT_HIERARCHY'){
          markAmortizationsAsPaidHorizontally(payHelper.remainingAmount, payments, unpaidAmortizations, advancePaymentHierarchy, payHelper);
        } else if (selectedAdvancePaymentStrategy == 'TRANSFER_TO_CBU'){
          that.excessNonHierarchyPayment = payHelper.remainingAmount;
          payHelper.zeroOutRemainingAmount();
        }else if(selectedAdvancePaymentStrategy == 'TRANSFER_TO_LINKED_ACCOUNT'){
          that.excessNonHierarchyPayment = payHelper.remainingAmount;
          payHelper.zeroOutRemainingAmount();
        }

      }
      return payHelper;
    }

    function calculateVerticalPayment(loan, selectedAdvancePaymentStrategy) {
      const loanType = loan.loanType;
      // here comes the sun
      const bnAmount = bn(that.paymentRequest.amount);

      const payHelper = new PayHelper(bnAmount);
      const payments = {};
      const duePaymentHierarchy = convertHierarchyToFields(loanType.duePaymentHierarchy);
      const advancePaymentHierarchy = convertHierarchyToFields(loanType.advancePaymentHierarchy);

      // 1. Pay due and overdue amortizations
      if (!payHelper.remainingAmount.isZero()) {
        let dueAmortizations = _.filter(loan.amortizationSchedule.list, a => a.status === 'DUE' || a.status === 'OVERDUE');
        markAmortizationsAsPaidVertically(payHelper.remainingAmount, payments, dueAmortizations, duePaymentHierarchy, payHelper);
      }
      // 2. Handle advance payment
      if (!payHelper.remainingAmount.isZero()) {
        let unpaidAmortizations = _.filter(loan.amortizationSchedule.list, a => a.status === 'UNPAID');
        if(selectedAdvancePaymentStrategy == 'PAYMENT_HIERARCHY'){
          markAmortizationsAsPaidVertically(payHelper.remainingAmount, payments, unpaidAmortizations, advancePaymentHierarchy, payHelper);
        } else if (['TRANSFER_TO_CBU','TRANSFER_TO_LINKED_ACCOUNT'].includes(selectedAdvancePaymentStrategy)){
          that.excessNonHierarchyPayment = payHelper.remainingAmount;
          payHelper.zeroOutRemainingAmount();
        }
      }
      return payHelper;
    }

    // Disable custom payment in case it's enabled and customPaymentAllowed changed to false
    $scope.$watch('$ctrl.customPaymentAllowed', () => {
      if (!that.customPaymentAllowed && that.customPayment) {
        that.customPayment = false;
        that.clearCustomPayment();
      }
    });

    function setInitialValues(loan) {
      const ZERO = new BigNumber(0);

      for (let amortization of loan.amortizationSchedule.list) {
        amortization.interestBalance_initial = amortization.interestBalance;
        amortization.totalPastDueInterestBalance_initial = amortization.totalPastDueInterestBalance;
        amortization.totalPenaltyBalance_initial = amortization.totalPenaltyBalance;
        amortization.cbuChargeBalance_initial = amortization.cbuChargeBalance;
        amortization.pfChargeBalance_initial = amortization.pfChargeBalance;
        amortization.tpChargeBalance_initial = amortization.tpChargeBalance;
        amortization.principalBalance_initial = amortization.principalBalance;
        amortization.amortizationBalance_initial = amortization.amortizationBalance;
        amortization.customFeesBalance_initial = amortization.customFeesBalance;

        amortization.interestBalance_paid = ZERO;
        amortization.totalPastDueInterestBalance_paid = ZERO;
        amortization.totalPenaltyBalance_paid = ZERO;
        amortization.cbuChargeBalance_paid = ZERO;
        amortization.pfChargeBalance_paid = ZERO;
        amortization.tpChargeBalance_paid = ZERO;
        amortization.principalBalance_paid = ZERO;
        amortization.amortizationBalance_paid = ZERO;
        amortization.customFeesBalance_paid = ZERO;

        amortization.status_initial = amortization.status;
      }
    }

    function markAmortizationsAsPaidHorizontally(amount, payments, amortizations, paymentHierarchy, payHelper) {
      if (amortizations.length === 0 || amount.isZero()) return;

      for (let a of amortizations) {
        for (let partField of paymentHierarchy) {
          if (payHelper.remainingAmount.isZero()) return;

          const paidAmount = payHelper.payPart(partField, amount, [a]);
          if (!paidAmount.isZero()) {
            const alreadyPaid = payments[partField];
            payments[partField] = !alreadyPaid ? paidAmount : paidAmount.plus(alreadyPaid);
          }
        }
      }
    }

    function markAmortizationsAsPaidVertically(amount, payments, amortizations, paymentHierarchy, payHelper) {
      if (amortizations.length === 0 || amount.isZero()) return;

      for (let partField of paymentHierarchy) {
        if (payHelper.remainingAmount.isZero()) return;

        const paidAmount = payHelper.payPart(partField, amount, amortizations);
        if (!paidAmount.isZero()) {
          const alreadyPaid = payments[partField];
          payments[partField] = !alreadyPaid ? paidAmount : paidAmount.plus(alreadyPaid);
        }
      }
    }


    function convertHierarchyToFields(hierarchy) {
      const map = {
        'INTEREST': 'interestBalance',
        'PAST_DUE_INTEREST': 'totalPastDueInterestBalance',
        'PENALTY': 'totalPenaltyBalance',
        'CBU': 'cbuChargeBalance',
        'PF': 'pfChargeBalance',
        'TP': 'tpChargeBalance',
        'PRINCIPAL': 'principalBalance',
        'CUSTOM': 'customFeesBalance'
      };

      let fieldHierarchy = [];
      for (let h of hierarchy) {
        fieldHierarchy.push(map[h]);
      }
      return fieldHierarchy;
    }

    function convertToBigNumber(loan) {
      convert(loan, num => num !== undefined ? new BigNumber(num) : new BigNumber(0));
    }

    function convertFromBigNumber(loan) {
      convert(loan, bn => bn !== undefined ? bn.toFixed(2) : new BigNumber(0));
    }

    function convert(loan, f) {
      for (let a of loan.amortizationSchedule.list) {
        a.principalBalance = f(a.principalBalance);
        a.interestBalance = f(a.interestBalance);
        a.uidInterestBalance = f(a.uidInterestBalance);
        a.totalPenaltyBalance = f(a.totalPenaltyBalance);
        a.totalPastDueInterestBalance = f(a.totalPastDueInterestBalance);
        a.cbuChargeBalance = f(a.cbuChargeBalance);
        a.pfChargeBalance = f(a.pfChargeBalance);
        a.tpChargeBalance = f(a.tpChargeBalance);
        a.paid = f(a.paid);
        a.amortizationBalance = f(a.amortizationBalance);
        a.customFeesBalance = f(a.customFeesBalance);
        a.principalBalance_paid = f(a.principalBalance_paid);
        a.interestBalance_paid = f(a.interestBalance_paid)
        a.cbuChargeBalance_paid = f(a.cbuChargeBalance_paid);
        a.pfChargeBalance_paid = f(a.pfChargeBalance_paid);
        a.tpChargeBalance_paid = f(a.tpChargeBalance_paid);
        a.totalPastDueInterestBalance_paid = f(a.totalPastDueInterestBalance_paid);
        a.totalPenaltyBalance_paid = f(a.totalPenaltyBalance_paid);
        a.customFeesBalance_paid = f(a.customFeesBalance_paid);
      }
    }


    function postProcess(loan) {
      for (let a of loan.amortizationSchedule.list) {
        // update amortization balance
        if (a.paid) {
          a.amortizationBalance = a.amortizationBalance.minus(a.paid);
        }
        // set new status
        if (a.amortizationBalance.isZero()) {
          a.status = 'PAID';
        } else if (!a.amortizationBalance.isEqualTo(a.amortizationBalance_initial)) {
          a.status = 'PART. PAID';
        }

        // assign classes
        a.class = {
          principalBalance: calculateClass(a.principalBalance, a['principalBalance_touched']),
          interestBalance: calculateClass(a.interestBalance, a['interestBalance_touched']),
          cbuChargeBalance: calculateClass(a.cbuChargeBalance, a['cbuChargeBalance_touched']),
          pfChargeBalance: calculateClass(a.pfChargeBalance, a['pfChargeBalance_touched']),
          tpChargeBalance: calculateClass(a.tpChargeBalance, a['tpChargeBalance_touched']),
          totalPastDueInterestBalance: calculateClass(a.totalPastDueInterestBalance, a['totalPastDueInterestBalance_touched']),
          totalPenaltyBalance: calculateClass(a.totalPenaltyBalance, a['totalPenaltyBalance_touched']),
          customFeesBalance: calculateClass(a.customFeesBalance, a['customFeesBalance_touched']),
          amortizationBalance: calculateClass(a.amortizationBalance, true)
        };
      }
    }

    function calculateClass(balance, touched) {
      if (!touched) return '';
      return balance.isZero() ? 'paid' : 'partially-paid';
    }

    that.calculatePartTotal = (part) => {
      if (part === 'penaltyAmount') {
        part = 'totalPenaltyAmount'
      }
      if (part === 'pastDueInterestAmount') {
        part = 'totalPastDueInterestAmount'
      }
      // check for balance
      let partBalance = part.replace('Amount', 'Balance');

      const sum = _.reduce(
        _.map(that.loan.amortizationSchedule.list, a => a[partBalance]),
        (a, b) => a.plus(b), new BigNumber(0)
      );

      return sum.toNumber();
    };

    that.calculateTotalCustomPayment = () => {
      that.paymentRequest.amount = bn(that.paymentRequest.principalAmount)
        .plus(bn(that.paymentRequest.interestAmount))
        .plus(bn(that.paymentRequest.cbuChargeAmount))
        .plus(bn(that.paymentRequest.pfChargeAmount))
        .plus(bn(that.paymentRequest.tpChargeAmount))
        .plus(bn(that.paymentRequest.pastDueInterestAmount))
        .plus(bn(that.paymentRequest.penaltyAmount))
        .plus(bn(that.paymentRequest.customFeesAmount)).toNumber();
    };

    function bn(amount) {
      return amount ? new BigNumber(amount) : new BigNumber(0);
    }

    that.onPaymentStrategyChange = () => {
      if (!that.customPayment) {
        that.calculatePreview();
      }
    };

    that.showColumn = (initialBalanceField) => {
      const totalInitialBalance = sum(that.modifiedAmortizations.map(a => a[initialBalanceField]));
      return totalInitialBalance.toNumber() !== 0;
    };

    that.visibleColumns = () => {
      const columns = [
        'totalPastDueInterestBalance_initial', 'totalPenaltyBalance_initial', 'cbuChargeBalance_initial',
        'pfChargeBalance_initial', 'tpChargeBalance_initial', 'customFeesBalance_initial'
      ];

      const nonHidableColumns = 6;
      const totalHidableColumns = columns.map(column => that.showColumn(column) ? 1 : 0).reduce(
        (a, b) => a + b,
        0
      );

      return nonHidableColumns + totalHidableColumns;
    };
  }
});
