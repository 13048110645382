import nxModule from 'nxModule';
import {sum} from 'shared/common/MathUtils';
import {DosriType, RelatedPartyTransactionType} from 'components/customer/profile/customer-profile.types';
import systemPropertyService from "system/systemPropertyService";
import './corporate-data.style.less';

const templateUrl = require('./corporate-data.template.html');
nxModule.component('customerProfileCorporateData', {
  templateUrl: templateUrl,
  bindings: {
    'profile': '=',
    'onNameChange': '&',
    'onWatchlistVerify': '&',
    'editMode': '='
  },
  controller: function ($scope, $timeout, dict, propertyConfigService) {
    let that = this;

    that.dict = dict;
    that.cfg = propertyConfigService;
    that.isRiskProfilingEnabled = systemPropertyService.getProperty('RISK_PROFILING_ENABLED') === 'TRUE';
    that.dosriTypes = Object.values(DosriType)
    // Corporate customer cannot be Company Employee
    that.relatedPartyTransactions = Object.values(RelatedPartyTransactionType)
      .filter(rpt => rpt !== RelatedPartyTransactionType.COMPANY_EMPLOYEE)
      .map(r => ({label: r, value: r}));
    that.corporationBusinessTypeDictEntry = that.dict['BUSINESS_TYPE'].find(de => de.code === 'CORPORATION');

    that.firmSize = () => {
      if (that.profile.incomeSources.filter(s => s.type === 'BUSINESS').length === 0) {
        return;
      }

      const businessAssets = sum(that.profile.incomeSources.filter(s => s.type === 'BUSINESS').map(s => s.companyTotalAssets)).toNumber()
      let firmSize;
      if (businessAssets <= 3000000) {
        firmSize = 'Micro Enterprise'
      } else if (businessAssets <= 15000000) {
        firmSize = 'Small Enterprise'
      } else if (businessAssets <= 100000000) {
        firmSize = 'Medium Enterprise'
      } else {
        firmSize = 'Large Enterprise'
      }
      return firmSize;
    }

    that.dosriChanged = () => {
      if (!that.profile.dosri) {
        that.profile.dosriType = null;
      }
    };

    that.rptChanged = () => {
      if (that.profile.corporateData.relatedPartyTransaction !== 'OTHER') {
        that.profile.corporateData.relatedPartyTransactionDescription = null;
      }
    };

    that.isCorporationBusinessType = () => {
      if (!that.corporationBusinessTypeDictEntry) {
        return false;
      }

      let businessTypeId = that.profile.corporateData.businessTypeId;
      return businessTypeId === that.corporationBusinessTypeDictEntry.id;
    }

    that.businessTypeChanged = () => {
      if (!that.isCorporationBusinessType()) {
        that.profile.corporateData.conglomerateId = null;
      }
    }

    that.nameChanged = () => {
      that.onNameChange();
      that.verifyDone = false;
    }

    that.watchlistVerify = async (e) => {
      e.preventDefault();

      that.onWatchlistVerify({
        input: {
          customerId: this.profile.id,
          customerType: this.profile.customerType,
          name1: this.profile.corporateData.businessName
        }
      });
    }
  }
});
