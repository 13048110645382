import nxModule from 'nxModule';

import templateUrl from './compliance-list.template.html';
import './compliance-list.style.less';

class ComplianceListView {
  async $onInit() {
    this.options = [
      {name: 'Risk assessment', url: '#!/admin/compliance/risk/assessment'},
      {name: 'Risk profiling categories', url: '#!/admin/compliance/risk/categories'},
      {name: 'Watchlist', url: '#!/admin/compliance/watchlist'},
    ];
  }

  openUrl(option) {
    return option.url;
  }
}

nxModule.component('complianceListView', {
  templateUrl,
  controller: ComplianceListView
});
