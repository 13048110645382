import { ReactElement, useState, useEffect } from "react";
import CustomerIndividual from "./CustomerIndividual";
import CustomerCorporate from "./CustomerCorporate";
import { useParams } from "react-router";
import { CustomerProfile } from '../../../../app/components/customer/profile/customer-profile.types';
import axios from "axios";
import { NxLoader } from "@nextbank/ui-components";
import useAxios from "axios-hooks";
import useDictionaryEnums from "hooks/useDictionaryEnums";

interface SubjectProps {
    getCustomerData: (customerId: string) => Promise<CustomerProfile>;
}

const Subject = ({ getCustomerData }: SubjectProps): ReactElement => {
    const { customerId } = useParams<{ customerId: string }>();
    const [customer, setCustomer] = useState<CustomerProfile | null>(null);
    const [cocreeCustomer, setCocreeCustomer] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { dictionaryEnumsData, loading: dictionaryEnumsLoading } = useDictionaryEnums();

    useEffect(() => {
        async function fetchCustomerData() {
            try {
                const data = await getCustomerData(customerId);
                const cocreeCustomerData = await axios.get(`/cocree/customers/${customerId}`)
                setCustomer(data);
                setCocreeCustomer(cocreeCustomerData)
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch customer data');
                setLoading(false);
            }
        }

        fetchCustomerData();
    }, [customerId]);

    if (loading || dictionaryEnumsLoading) return <NxLoader/>;
    if (error) return <div>{error}</div>;
    if (!customer) return <div>No customer data available</div>;
    return (
      <div>
        <div>
          Customer Type: <span style={{ fontWeight: "bolder", fontSize: "16px" }}>{cocreeCustomer.data.customerType}</span>
        </div>
        &nbsp;
        {cocreeCustomer.data.customerType === "INDIVIDUAL" ? (
          <CustomerIndividual
            dictionaryEnums={dictionaryEnumsData}
            birthCountryId={customer?.individualData?.birthCountryId}
            customerId={Number(customerId)}
            cocreeCustomer={cocreeCustomer}
          />
        ) : (
          <CustomerCorporate
            dictionaryEnums={dictionaryEnumsData}
            customerId={Number(customerId)}
            cocreeCustomer={cocreeCustomer}
          />
        )}
      </div>
    );
}

export default Subject;