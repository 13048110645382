import nxModule from 'nxModule';
import templateUrl from './gl-payment.template.html';
import './gl-payment.style.less';
import {ActionCategory} from '../../../administration/transactions/action-category.types';
import {Operation} from "components/service/operation.types";
import {ILocationService} from 'angular';

class GlPayment {

  protected operation!: Operation & {categoryId?: number};
  protected category?: ActionCategory;
  protected withoutRemarks: boolean = true;

  static $inject = ['$location'];

  constructor($location: ILocationService) {
    if ($location.path().includes('gl-payment') || $location.path().includes('check-payment')) {
      this.withoutRemarks = false;
    }
  }

  categoryChanged(): void {
    this.operation.categoryId = this.category?.id;
  }
}

nxModule.component('glPayment', {
  templateUrl,
  bindings: {
    operation: '=',
    remarks: '=',
    actionType: '<'
  },
  controller: GlPayment
});
