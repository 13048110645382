import { ReactElement, useState } from "react"
import {
    NxButton,
    NxStack,
    NxSelect,
    NxLoader
} from '@nextbank/ui-components';
import useAxios from "axios-hooks";
import { useParams } from "react-router";
import { HttpError } from "tools/HttpTypes";
import ExposureOCAForm from './ExposureOCAForm';
import ExposureDEIForm from "./ExposureDEIForm";
import useDictionaryEnums from "hooks/useDictionaryEnums";
import useLoansIndustry from "hooks/useLoansIndustry";
import tokenStore from "authentication/tokenStore";
import commandAccessService from 'command/commandAccessService';

const RECORD_TYPE = [
    {
        label: "Other credit accommodation",
        value: "OTHER_CREDIT_ACCOMMODATION"
    },
    {
        label: "Debt & equity investment",
        value: "DEBT_AND_EQUITY_INVESTMENT"
    }
];

function getParentEntry(array: any) {
  return array.filter((item: any) => item.parentCode === null)
}
function renameAttributes(arr: any) {
    return arr.map((obj: any) => ({
        value: obj.id,
        label: obj.name,
        ...Object.fromEntries(
            Object.entries(obj).filter(([key]) => key !== 'id' && key !== 'name')
        )
    }));
}

const ExposureDetails = (): ReactElement => {
    const { customerId } = useParams<{ customerId: string }>();
    const { exposureId } = useParams<{ exposureId: string }>();
    const [{ data: exposure, loading: isExposureLoading }, fetchExposure] = useAxios<any, HttpError>(
        `/cocree/customers/${customerId}/exposures/${exposureId ?? ''}`
    );
    const [reportType, setReportType] = useState<any>(null);
    const [showForm, setShowForm] = useState<boolean>(false);
    const { dictionaryEnumsData, loading: dictionaryEnumsLoading } = useDictionaryEnums();
    const { loansIndustryData, loading: loansIndustryLoading } = useLoansIndustry();
    const userBranchId = tokenStore.getTokenDetails().userBranchId;
    const hasAccess = commandAccessService.canExecute("CreateExposure") || commandAccessService.canExecute("UpdateExposure");

  if (dictionaryEnumsLoading || loansIndustryLoading) return <></>;

  const isOCA = (showForm && reportType === "OTHER_CREDIT_ACCOMMODATION") ||
    exposure?.recordType === "OTHER_CREDIT_ACCOMMODATION";

  const isDEI = (showForm && reportType === "DEBT_AND_EQUITY_INVESTMENT") ||
    exposure?.recordType === "DEBT_AND_EQUITY_INVESTMENT";
    
    return (
      <>
        {(!showForm || !reportType) && !exposure?.id && !exposureId ? (
          <div>
            <NxStack>
              <NxSelect
                value={reportType}
                onChange={(changeValue): void => {
                  setReportType(changeValue!);
                  setShowForm(false);
                }}
                options={RECORD_TYPE}
                label="Record Type"
              />
              <NxButton
                disabled={!reportType}
                onClick={() => {
                  setShowForm(true);
                }}
              >
                Add Exposure
              </NxButton>
            </NxStack>
          </div>
        ) : exposureId && !reportType && !exposure?.recordType ? (
          <NxLoader />
        ) : null}

        {isOCA ? <div>
          <ExposureOCAForm
            hasAccess={hasAccess}
            exposureData={exposure}
            customerId={Number(customerId)}
            userBranchId={userBranchId}
            dictionaryEnums={dictionaryEnumsData}
            loansIndustry={renameAttributes(getParentEntry(loansIndustryData))}
          />
        </div> : null}

        {isDEI ? <div>
          <ExposureDEIForm
            hasAccess={hasAccess}
            exposureData={exposure}
            customerId={Number(customerId)}
            userBranchId={userBranchId}
            dictionaryEnums={dictionaryEnumsData}
          />
        </div> : null}
      </>
    );
}

export default ExposureDetails;