import { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';

const useDictionaryEnums = () => {
    const [dictionaryEnumsData, setDictionaryEnumsData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [{ data: dictionaryEnums, loading: dictionaryEnumsLoading }, getDictionaryEnums] = useAxios<any>(
        "/dictionaries/enums",
        {
            manual: true,
        }
    );

    useEffect(() => {
        const fetchDictionaryEnums = async () => {
            const cacheKey = 'nx__dictionariesEnums.data.key';
            const cachedData = localStorage.getItem(cacheKey);

            try {
                if (cachedData && cachedData !== 'undefined') {
                    setDictionaryEnumsData(JSON.parse(cachedData));
                } else {
                    const enums = await getDictionaryEnums();
                    setDictionaryEnumsData(enums.data);
                    localStorage.setItem(cacheKey, JSON.stringify(enums.data));
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchDictionaryEnums();
    }, [getDictionaryEnums]);

    return { dictionaryEnumsData, loading };
};

export default useDictionaryEnums;