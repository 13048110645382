import {PropertyConfigService} from 'components/service/property-config.service';
import nxModule from 'nxModule';
import {Dict} from 'shared/common/dict.types';
import Authentication from 'shared/utils/authentication';
import systemPropertyService from '../../../../react/system/systemPropertyService';
import {RecursivePartial} from '../../../shared/utils/RecursivePartial';
import {
  Address,
  CorporateData,
  CustomerProfile,
  IDDocument,
  IndividualData
} from './customer-profile.types';

export class ProfileUtilityService {

  constructor(private dict: Dict,
              private authentication: Authentication,
              private propertyConfigService: PropertyConfigService) {}

  initCustomerProfile(): Promise<RecursivePartial<CustomerProfile>> {
    return new Promise<RecursivePartial<CustomerProfile>>(resolve => {
      this.dict.onLoadingComplete(async () => {

        const profile: RecursivePartial<CustomerProfile> & {
          addresses: Partial<Address>[];
          idDocuments: Partial<IDDocument>[];
          individualData: Partial<IndividualData>;
          corporateData: Partial<CorporateData>;
        } = {
          branchId: this.authentication.context.branchId,
          userId: this.authentication.context.id,
          status: 'PROSPECT',
          customerType: 'INDIVIDUAL',
          dosri: false,

          individualData: {
            pep: false,
            sanctionedEntity: false,
            vip: false,
          },

          corporateData: {},
          externalAccounts: [],

          phones: [],
          addresses: [],
          idDocuments: [],
          incomeSources: [],
          relatedCustomers: [],
          relatedNonCustomers: [],
          extractedPhones: {},
          creditLineAllowed: false,
          isPwd: false
        };

        const minAddresses = systemPropertyService.getProperty('CIF_MIN_ADDRESS_SECTIONS') || '0';
        const minDocuments = systemPropertyService.getProperty('CIF_MIN_DOCUMENT_SECTIONS') || '0';

        // FIXME = default values for country codes should be fetched from [cfg]
        const ph = this.dict.getId('COUNTRY', 'PH');
        if(minAddresses && parseInt(minAddresses)) {
          profile.addresses.push({countryId : ph});
        }

        if(minDocuments && parseInt(minDocuments)) {
          profile.idDocuments.push({ primary: true, countryId : ph });
        }

        const envDefaultRiskLevel = this.propertyConfigService.defaultValue('customer', 'compliance', 'risk_level');
        const defaultRiskLevel: number = this.dict.getId('CUSTOMER_RISK_LEVEL', envDefaultRiskLevel);
        profile.individualData.riskLevelId = defaultRiskLevel;
        profile.corporateData.riskLevelId = defaultRiskLevel;

        profile.individualData.citizenshipId = ph;
        profile.individualData.residenceId = ph;

        const isMembershipTypeVisible = this.propertyConfigService.visible('customer', 'cooperative_data', 'membership_type');
        if (isMembershipTypeVisible) {
          const defaultMembershipType = this.propertyConfigService.defaultValue('customer', 'cooperative_data', 'membership_type');
          if (defaultMembershipType) {
            profile.membershipTypeId = this.dict.getId('MEMBERSHIP_TYPE', defaultMembershipType);
          }
        }

        resolve(profile);
      });
    });
  }
}

nxModule.factory('profileUtilityService', ProfileUtilityService);
