import { useState, useEffect } from "react";
import useAxios from "axios-hooks";

interface LoanIndustry {
    name: string;
    code: string;
    id: number;
    parentCode?: string;
}

const useLoansIndustry = (): { loansIndustryData: LoanIndustry[] | null; loading: boolean } => {
  const [loansIndustryData, setLoansIndustryData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [{ data: loansIndustry, loading: loansIndustryLoading }, getLoansIndustry] = useAxios<any>(
    `/products/loans/loan-purposes-to-industry`,
    {
      manual: true,
    }
  );

  useEffect(() => {
    const fetchLoansIndustry = async () => {
      const cacheKey = "nx__loansIndustry.data.key";
      const cachedData = localStorage.getItem(cacheKey);

      try {
        if (cachedData && cachedData !== "undefined") {
          setLoansIndustryData(JSON.parse(cachedData)?.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        } else {
          const enums = await getLoansIndustry();
          setLoansIndustryData(enums.data?.sort((a: any, b: any) => a.name.localeCompare(b.name)));
          localStorage.setItem(cacheKey, JSON.stringify(enums.data));
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchLoansIndustry();
  }, [getLoansIndustry]);

  return { loansIndustryData, loading };
};

export default useLoansIndustry;
