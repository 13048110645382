import moment from 'moment';
import {isNil} from 'lodash/fp';

export default class DateFormatter {
  prettyDateWithDay(date?: string): string {
    if (!isNil(date)) {
      return moment(date).format("MMM Do YYYY, ddd");
    }
    return "";
  }

  prettyDate(date?: string): string {
    if (!isNil(date)) {
      return moment(date).format("MMM Do YYYY");
    }
    return "";
  }
}